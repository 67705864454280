import {
  Table,
  Button,
  Modal,
  Input,
  Form,
  message,
  Select,
  Checkbox,
  Upload,
} from "antd";
import React, { useState, useEffect, useCallback } from "react";
import axios from "../../utils/axios";
import "./index.css";

import {
  DeleteOutlined,
  EditOutlined,
  SettingOutlined,
  LinkOutlined,
  CheckCircleOutlined,
  FileAddOutlined,
  UploadOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router";
import BarLoader from "react-spinners/BarLoader";
import { SERVER_URL } from "../../Constants";
import InfluencerTags from "./InfluencerTags";
import customAxios from "../../utils/axios";
import { isImage } from "../../utils/utils";
import ReactPlayer from "react-player/file";

const formItemLayout = null;
const { TextArea } = Input;

const normFile = (e) => {
  // console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const Influencer = () => {
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();
  const [formFile] = Form.useForm();
  const [prohibitedTopics, setProhibitedTopics] = useState([]);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  const [data, setData] = useState([
    {
      id: "",
      name: "",
      lora_model_name: "",
      server_ip_1: "",
      server_ip_2: "",
      server_ip_3: "",
      server_ip_4: "",
      character: "",
      character_last_name: "",
      voice_id: "",
      voice_id_2: "",
      voice_model_id: "",
      voice_model_id_2: "",
      open_ai_model: "",
      link_search_model: "",
      intent_engine_model: "",
      prohibited_topics: "",
      temperature: "",
      top_p: "",
      top_k: "",
      min_length: "",
      max_new_tokens: "",
      notification_frequency: "",
      notification_message: "",
      tags: "",
      is_on_home: "",
      description: "",
      likes: "",
      comments: "",
      shares: "",
      followers: "",
      languages: "",
      location: "",
      gender: "",
      cover: "",
      image_examples_urls: [],
      image_examples_url: "",
      examples_prompts: "",
      image_generation_only: "",
      bonus_content_generation_only: "",
      link_search_model_mistral: "",
      intent_engine_model_mistral: "",
    },
  ]);
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  const [isActiveModalOpen, setIsActiveModalOpen] = useState(false);
  const [publishRecord, setPublishRecord] = useState(null);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [isOnHome, setIsOnHome] = useState(false);
  const [isImageGenerationOnly, setIsImageGenerationOnly] = useState(false);
  const [isBonusGenerationOnly, setIsBonusGenerationOnly] = useState(false);
  const [influencerId, setInfluencerId] = useState(null);

  const [isUploadFileModal, setIsUploadFileModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [coverPictureList, setCoverPictureList] = useState([]);
  const [examplesPictureList, setExamplesPictureList] = useState([]);
  const [profilePictureList, setProfilePictureList] = useState([]);
  const [isMainListOn, setIsMainListOn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const token = window.localStorage.getItem("accessToken");

    if (token) {
      const decoded = jwtDecode(token);
      setCurrentUser(decoded.sub);
    }
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <div>{text}</div>,
    },
    {
      title: (
        <>
          <div>Name</div>
          <div>(Character)</div>
        </>
      ),
      dataIndex: "name",
      key: "name",
      render: (text, values) => (
        <>
          <div>{text}</div>
          <div>({values.character})</div>
        </>
      ),
    },
    {
      title: "Visible",
      dataIndex: "visible",
      key: "visible",
      render: (number) => (number ? <div>Yes</div> : <div>No</div>),
    },
    {
      title: "On Main",
      dataIndex: "is_on_home",
      key: "is_on_home",
      render: (number) => (number ? <div>Yes</div> : <div>No</div>),
    },
    {
      title: "Only Images",
      dataIndex: "image_generation_only",
      key: "image_generation_only",
      render: (number) => (number ? <div>Yes</div> : <div>No</div>),
    },
    {
      title: "Only Bonus Content",
      dataIndex: "bonus_content_generation_only",
      key: "bonus_content_generation_only",
      render: (number) => (number ? <div>Yes</div> : <div>No</div>),
    },
    {
      title: "Wait List On",
      description: "Wait List On",
      dataIndex: "wait_list_on",
      key: "wait_list_on",
      render: (number) => <div>{number ? "Yes" : "No"}</div>,
    },
    {
      title: "Profile Picture",
      dataIndex: "profilePic",
      key: "profilePic",
      render: (text) =>
        text ? (
          <img
            src={text}
            alt={text ? "Profile picture" : "No Profile Pic"}
            style={{ width: "50px", height: "auto" }}
          />
        ) : (
          "No Profile Pic"
        ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (obj) => <InfluencerTags tags={obj} />,
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "0.5rem" }}>
          <Button
            type="primary"
            danger
            onClick={(event) => navigate("/influencer/" + record.id)}
            title={"Add links"}
          >
            <LinkOutlined />
          </Button>
          <Button
            type="primary"
            danger
            onClick={(event) => {
              handlePublishModal(record);
            }}
            title={"Publish on main page"}
          >
            <CheckCircleOutlined />
          </Button>
          <Button
            type="primary"
            danger
            onClick={(event) => handleOpenUploadFileModal(record.id)}
            title={"Add images, bg_video"}
          >
            <FileAddOutlined />
          </Button>
          <Button
            type="primary"
            danger
            onClick={(event) => navigate("/influencerSettings/" + record.id)}
            title={"Settings"}
          >
            <SettingOutlined />
          </Button>
          <Button
            type="primary"
            danger
            onClick={(event) => {
              editDetail(event, record);
            }}
            title={"Edit"}
          >
            <EditOutlined />
          </Button>
          <Button
            type="primary"
            danger
            onClick={(event) => {
              event.stopPropagation();
              handleOpenDeleteModal(record.id);
            }}
            title={"Delete"}
          >
            <DeleteOutlined />
          </Button>
          <Button
            type="primary"
            danger
            onClick={(event) => duplicateInfluencer(record.id)}
            title={"Duplicate"}
          >
            <CopyOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const onChange = (e) => {
    setIsOnHome(e.target.checked);
  };
  const onChangeOnlyImgGen = (e) => {
    setIsImageGenerationOnly(e.target.checked);
  };
  const onChangeOnlyBonusGen = (e) => {
    setIsBonusGenerationOnly(e.target.checked);
  };

  function editDetail(event, record) {
    event.stopPropagation();

    setIsEdit(record.id);
    const tags = record.tags.map((tag) => {
      return { value: tag.id, label: tag.title };
    });

    setIsOnHome(record.is_on_home === 1);
    setIsImageGenerationOnly(record.image_generation_only === 1);
    setIsBonusGenerationOnly(record.bonus_content_generation_only === 1);
    setIsMainListOn(record.wait_list_on === 1);
    form.setFieldsValue({
      name: record.name,
      lora_model_name: record.lora_model_name,
      server_ip_1: record.server_ip?.split(";")[0] || "",
      server_ip_2: record.server_ip?.split(";")[1] || "",
      server_ip_3: record.server_ip?.split(";")[2] || "",
      server_ip_4: record.server_ip?.split(";")[3] || "",
      character: record.character,
      character_last_name: record.character_last_name,
      voice_id: record.voice_id,
      voice_id_2: record.voice_id_2,
      voice_model_id: record.voice_model_id,
      voice_model_id_2: record.voice_model_id_2,
      link_search_model_mistral: record.link_search_model_mistral,
      intent_engine_model_mistral: record.intent_engine_model_mistral,
      open_ai_model: record.open_ai_model,
      link_search_model: record.link_search_model,
      intent_engine_model: record.intent_engine_model,
      image_examples_url: record.image_examples_url,
      prohibited_topics: record.prohibited_topics?.replace(/"/g, ""),
      tags: tags,
      is_on_home: record.is_on_home === 1,
      description: record.description,
      likes: record.likes,
      comments: record.comments,
      shares: record.shares,
      followers: record.followers,
      languages: record.languages,
      location: record.location,
      gender: record.gender,
      image_generation_only: record.image_generation_only === 1,
      bonus_content_generation_only: record.bonus_content_generation_only === 1,
      wait_list_on: record.wait_list_on,
      text_msg_price: record.text_msg_price,
      audio_price: record.audio_price,
      image_returned_cost: record.image_returned_cost,
      swf_image_price: record.swf_image_price,
      nswf_image_price: record.nswf_image_price,
      human_generated_percent: record.human_generated_percent,
      ai_generated_percent: record.ai_generated_percent,
      net_profit_cut: record.net_profit_cut,
    });
    showAddUserModal();
  }

  const getUserData = useCallback(() => {
    axios.get(SERVER_URL + "/influencer").then(function (res) {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const fetchData = () => {
      customAxios.get(SERVER_URL + "/tags").then(function (res) {
        const options = res.data.map((tag) => {
          return { value: tag.id, label: tag.title };
        });
        setTagsOptions(options);
      });
    };

    fetchData();
    if (currentUser) {
      getUserData();
    }
  }, [currentUser, getUserData]);

  const duplicateInfluencer = async (id) => {
    try {
      const { data } = await axios.post(SERVER_URL + "/influencer/duplicate", {
        id: id,
      });
      setData(data);
      messageApi.open({
        type: "success",
        content: "Influencer duplicated successfully!",
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Failed to duplicate influencer!",
      });
    }
  };

  const handleOpenDeleteModal = (id) => {
    setIsDeleteModalOpen(true);
    setDeleteId(id);
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeleteId("");
  };
  const deleteRecord = async () => {
    try {
      const { data } = await axios.delete(SERVER_URL + "/influencer", {
        data: {
          id: deleteId,
        },
      });
      setData(data);

      messageApi.open({
        type: "warning",
        content: "Make sure to restart the Telegram bot to apply the changes!",
      });
      handleCloseDeleteModal();
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const showAddUserModal = () => {
    setIsAddUserModalOpen(true);
  };

  const handlePublishModal = (record) => {
    setPublishRecord(record);
    setIsActiveModalOpen(true);
  };

  const handleClosePublishModal = () => {
    setPublishRecord(null);
    setIsActiveModalOpen(false);
    setIsOnHome(false);
  };

  const publishOnMain = async () => {
    const isOnHome = publishRecord.is_on_home ? false : true;
    const { data } = await axios.put(SERVER_URL + "/influencer", {
      is_on_home: isOnHome,
      id: publishRecord.id,
    });
    setData(data);
    setPublishRecord(null);
    setIsActiveModalOpen(false);
  };
  const handleUserOk = async () => {
    try {
      const user = form.getFieldsValue();

      if (
        (user.text_msg_price && user.text_msg_price < 0) ||
        (user.audio_price && user.audio_price < 0) ||
        (user.image_returned_cost && user.image_returned_cost < 0) ||
        (user.swf_image_price && user.swf_image_price < 0) ||
        (user.nswf_image_price && user.nswf_image_price < 0)
      ) {
        messageApi.open({
          type: "error",
          content: "Price can't be negative!",
        });
        return;
      }
      if (
        (user.human_generated_percent &&
          (user.human_generated_percent < 0 ||
            user.human_generated_percent > 100)) ||
        (user.ai_generated_percent &&
          (user.ai_generated_percent < 0 || user.ai_generated_percent > 100)) ||
        (user.net_profit_cut &&
          (user.net_profit_cut < 0 || user.net_profit_cut > 100))
      ) {
        messageApi.open({
          type: "error",
          content: "Percentage should be between 0 and 100!",
        });
        return;
      }

      if (prohibitedTopics.length > 1) {
        user.prohibited_topics = prohibitedTopics;
      }

      // Set the server urls as a single string separated by a semicolon
      user.server_ip = user.server_ip_1;
      if (user.server_ip_2) {
        user.server_ip += ";" + user.server_ip_2;
      }
      if (user.server_ip_3) {
        user.server_ip += ";" + user.server_ip_3;
      }
      if (user.server_ip_4) {
        user.server_ip += ";" + user.server_ip_4;
      }

      user.is_on_home = isOnHome ? 1 : 0;
      user.image_generation_only = isImageGenerationOnly ? 1 : 0;
      user.bonus_content_generation_only = isBonusGenerationOnly ? 1 : 0;
      user.wait_list_on = isMainListOn ? 1 : 0;
      user.voice_model_id = document.getElementById("voice_model_id").value;
      user.voice_model_id_2 = document.getElementById("voice_model_id_2").value;

      if (isEdit > 0) {
        const { data } = await axios.put(SERVER_URL + "/influencer", {
          ...user,
          id: isEdit,
        });
        setData(data);
      } else {
        const { data } = await axios.post(SERVER_URL + "/influencer", user);
        setData(data);
      }
      setIsAddUserModalOpen(false);
      setProhibitedTopics([]);
      // user.prohibited_topics = prohibitedTopics;

      messageApi.open({
        type: "warning",
        content: "Make sure to restart the Telegram bot to apply the changes!",
      });
      clearFormData();
    } catch (error) {
      console.log("--ERROR--", error);
      if (error.response) {
        // Try to get the error message from the server otherwise set it to a default message
        const errorMessage =
          error.response.data.error || "Something went wrong!";

        messageApi.open({
          type: "error",
          content: errorMessage,
        });
      }
    }
  };

  const handleUserCancel = () => {
    if (isEdit > 0) {
      setIsEdit("");
    }
    setIsAddUserModalOpen(false);
    setIsOnHome(false);
    setIsMainListOn(false);
    clearFormData();
  };
  function clearFormData() {
    form.resetFields();
  }

  const handleUploadFileModalOk = async () => {
    try {
      setFileUploadLoading(true);
      const values = formFile.getFieldsValue();

      const formData = new FormData();
      const prev_examples = examplesPictureList
        .filter((example) => example?.status === "done")
        .map((example) => example.url)
        .join(",");
      formData.append("influencer_id", influencerId);
      formData.append(
        "prev_profile",
        profilePictureList.length > 0 ? profilePictureList[0].url : ""
      );
      formData.append(
        "prev_cover",
        coverPictureList.length > 0 ? coverPictureList[0].url : ""
      );
      formData.append("prev_examples", prev_examples);
      let examples_prompts = "";
      examples_prompts += values.promptFirst ? values.promptFirst + "," : ",";
      examples_prompts += values.promptSecond ? values.promptSecond + "," : ",";
      examples_prompts += values.promptThird ? values.promptThird + "," : ",";
      examples_prompts += values.promptFourth ? values.promptFourth : "";
      formData.append("examples_prompts", examples_prompts);

      const profile = values.profile;
      const cover = values.cover;
      const examples = values.examples;
      if (profile && profile.length > 0) {
        const profilePicture = profile[0];
        formData.append("files", profilePicture.originFileObj);
        formData.append("profile_name", profilePicture.name);
      } else {
        if (!profilePictureList.length) {
          messageApi.error("Profile picture is required!");
          return;
        }
        formData.append("profile_name", "");
      }
      if (cover && cover.length > 0) {
        const coverPicture = cover[0];
        formData.append("files", coverPicture.originFileObj);
        formData.append("cover_name", coverPicture.name);
      } else {
        formData.append("cover_name", "");
      }
      if (examples && examples.length > 0) {
        for (let i = 0; i < examples.length; i++) {
          const example = examples[i];
          formData.append("files", example.originFileObj);
          formData.append("example_name", example.name);
        }
        // } else {
        //   if (examplesPictureList.length < 4) {
        //     messageApi.error("Add 4 or less images!");
        //     return;
        //   }
      }

      let response = await customAxios.post(
        SERVER_URL + "/influencer/files",
        formData,
        {}
      );

      setFileUploadLoading(false);
      handleCloseUploadFileModal();
      messageApi.open({
        type: "success",
        content: "Uploaded successful!",
      });
      window.location.reload();
    } catch (error) {
      console.log("--ERROR--", error);
      if (error.response) {
        // Try to get the error message from the server otherwise set it to a default message
        const errorMessage =
          error.response.data.error || "Something went wrong!";
        messageApi.open({
          type: "error",
          content: errorMessage,
        });
      }
    } finally {
      setFileUploadLoading(false);
    }
  };

  const handleOpenUploadFileModal = (record_id) => {
    const freshRecord = data.find((item) => item.id === record_id);
    setIsUploadFileModal(true);
    setInfluencerId(record_id);
    setCoverPictureList(
      freshRecord.cover
        ? [
            {
              uid: freshRecord.cover,
              name: freshRecord.cover,
              status: "done",
              url: freshRecord.cover,
            },
          ]
        : []
    );
    setExamplesPictureList(
      freshRecord.image_examples_urls
        ? freshRecord.image_examples_urls.split(",").map((example) => {
            return {
              uid: example,
              name: example,
              status: "done",
              url: example,
            };
          })
        : []
    );
    setProfilePictureList(
      freshRecord.profilePic
        ? [
            {
              uid: freshRecord.profilePic,
              name: freshRecord.profilePic,
              status: "done",
              url: freshRecord.profilePic,
            },
          ]
        : []
    );
    formFile.setFieldsValue({
      promptFirst: freshRecord.examples_prompts
        ? freshRecord.examples_prompts.split(",")[0]
        : "",
      promptSecond: freshRecord.examples_prompts
        ? freshRecord.examples_prompts.split(",")[1]
        : "",
      promptThird: freshRecord.examples_prompts
        ? freshRecord.examples_prompts.split(",")[2]
        : "",
      promptFourth: freshRecord.examples_prompts
        ? freshRecord.examples_prompts.split(",")[3]
        : "",
    });
  };

  const handleCloseUploadFileModal = () => {
    setIsUploadFileModal(false);
    formFile.resetFields();
    setInfluencerId(null);
    setCoverPictureList([]);
    setExamplesPictureList([]);
    setProfilePictureList([]);
  };
  const onChangeMainListOn = (e) => {
    setIsMainListOn(e.target.checked);
  };

  const forceReloadInfluencerSettings = async () => {
    try {
      await axios.get(SERVER_URL + "/influencer/forceReload");
      messageApi.open({
        type: "success",
        content: "Influencer settings reloaded successfully!",
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Failed to reload influencer settings!",
      });
    }
  };

  return (
    <div>
      {contextHolder}
      <div>
        <Button type="primary" onClick={() => showAddUserModal()}>
          Add Influencer
        </Button>
      </div>
      <div>
        <Button type="primary" onClick={() => forceReloadInfluencerSettings()}>
          Force Reload Influencer Settings
        </Button>
      </div>
      <div>
        {loading && (
          <BarLoader
            color="#1677ff"
            cssOverride={{
              display: "block",
              margin: "10vh auto",
              borderColor: "red",
            }}
            size={150}
          />
        )}
        {!loading && (
          <Table
            style={{ marginTop: "8px" }}
            columns={columns}
            dataSource={data}
            rowKey="id"
            pagination={{
              position: ["topRight", "bottomRight"],
            }}
          />
        )}
      </div>
      <Modal
        title={isEdit > 0 ? "Edit Influencer" : "Add Influencer"}
        open={isAddUserModalOpen}
        onOk={handleUserOk}
        onCancel={handleUserCancel}
        width={1200}
      >
        <Form form={form} name="dynamic_rule" layout="vertical">
          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ width: "100%" }}>
              <Form.Item {...formItemLayout} name="tags" label="Tags">
                <Select
                  name="tags"
                  mode="multiple"
                  options={tagsOptions}
                  // options={[
                  //   { value: 1, label: "True" },
                  //   { value: 0, label: "False" }
                  // ]}
                />
              </Form.Item>

              <Form.Item {...formItemLayout} name="likes" label="Likes">
                <Input type="number" min={0} placeholder="Please input likes" />
              </Form.Item>

              <Form.Item {...formItemLayout} name="comments" label="Comments">
                <Input
                  type="number"
                  min={0}
                  placeholder="Please input comments"
                />
              </Form.Item>

              <Form.Item {...formItemLayout} name="shares" label="Shares">
                <Input
                  type="number"
                  min={0}
                  placeholder="Please input shares"
                />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="followers"
                label="Followers (Fans)"
              >
                <Input
                  type="number"
                  min={0}
                  placeholder="Please input followers (Fans)"
                />
              </Form.Item>

              <Form.Item {...formItemLayout} name="languages" label="Languages">
                <Input
                  type="text"
                  placeholder="Please input languages separate bu comma delimeter"
                />
              </Form.Item>

              <Form.Item {...formItemLayout} name="location" label="Location">
                <Input type="text" placeholder="Please input location" />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="gender"
                label="Gender"
                rules={[{ required: false, message: "Please select gender" }]}
              >
                <Select
                  name="gender"
                  options={[
                    { value: "male", label: "male" },
                    { value: "female", label: "female" },
                    { value: "all", label: "all" },
                  ]}
                />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="image_examples_url"
                label="Url for 'Click here to see more examples'"
              >
                <Input type="text" min={1} placeholder="Please  input url" />
              </Form.Item>

              <Form.Item {...formItemLayout} name="is_on_home">
                <Checkbox checked={isOnHome} onChange={onChange}>
                  Is in Header of Homepage
                </Checkbox>
              </Form.Item>

              <Form.Item {...formItemLayout} name="image_generation_only">
                <Checkbox
                  checked={isImageGenerationOnly}
                  onChange={onChangeOnlyImgGen}
                >
                  Influencer generate only images
                </Checkbox>
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="bonus_content_generation_only"
              >
                <Checkbox
                  checked={isBonusGenerationOnly}
                  onChange={onChangeOnlyBonusGen}
                >
                  Influencer generate only bonus content
                </Checkbox>
              </Form.Item>

              <Form.Item {...formItemLayout}>
                <Checkbox checked={isMainListOn} onChange={onChangeMainListOn}>
                  Wait List On
                </Checkbox>
              </Form.Item>
            </div>

            <div style={{ width: "100%" }}>
              <Form.Item
                {...formItemLayout}
                name="character"
                label="Character First Name"
                rules={[
                  {
                    required: true,
                    message: "Please input character first name",
                  },
                ]}
              >
                <Input
                  type="text"
                  min={1}
                  placeholder="Please input character first name"
                />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="character_last_name"
                label="Character Last Name"
                rules={[{ message: "Please input character last name" }]}
              >
                <Input
                  type="text"
                  min={1}
                  placeholder="Please input character last name"
                />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="name"
                label="AI Name (e.g., Tyler Tanner AI)"
                rules={[
                  { required: true, message: "Please input influencer name" },
                ]}
              >
                <Input placeholder="Please input influencer name" />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="lora_model_name"
                label="Lora model name (e.g., Sub_Woman)"
                rules={[{ message: "Please input Lora model name" }]}
              >
                <Input placeholder="Please input Lora model name" />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="server_ip_1"
                label="Model Endpoint http://X.X.X.X"
                rules={[{ required: false, message: "Please input server ip" }]}
              >
                <Input
                  type="text"
                  min={1}
                  placeholder="Please input server ip"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="server_ip_2"
                label="Model Endpoint 2 http://X.X.X.X"
                rules={[{ required: false, message: "Please input server ip" }]}
              >
                <Input
                  type="text"
                  min={1}
                  placeholder="Please input server ip"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="server_ip_3"
                label="Model Endpoint 3 http://X.X.X.X"
                rules={[{ required: false, message: "Please input server ip" }]}
              >
                <Input
                  type="text"
                  min={1}
                  placeholder="Please input server ip"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="server_ip_4"
                label="Model Endpoint 4 http://X.X.X.X"
                rules={[{ required: false, message: "Please input server ip" }]}
              >
                <Input
                  type="text"
                  min={1}
                  placeholder="Please input server ip"
                />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="voice_id"
                label="Voice Id"
                rules={[{ required: false, message: "Please input voice_id" }]}
              >
                <Input
                  type="text"
                  min={1}
                  placeholder="Please input voice_id"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="voice_id_2"
                label="Voice Id 2 (for Elevenlabs API Key 2)"
                rules={[{ required: false, message: "Please input voice_id" }]}
              >
                <Input
                  type="text"
                  min={1}
                  placeholder="Please input voice_id"
                />
              </Form.Item>
              {/* dropdown with these options: eleven_multilingual_v2, eleven_multilingual_v1, eleven_monolingual_v1 or eleven_turbo_v2 */}
              <Form.Item
                {...formItemLayout}
                name="voice_model_id"
                label="Voice Model Id"
                rules={[
                  { required: false, message: "Please input voice_model_id" },
                ]}
              >
                <select
                  className="form-control"
                  name="voice_model_id"
                  id="voice_model_id"
                >
                  <option value="eleven_multilingual_v2">
                    eleven_multilingual_v2
                  </option>
                  <option value="eleven_multilingual_v1">
                    eleven_multilingual_v1
                  </option>
                  <option value="eleven_monolingual_v1">
                    eleven_monolingual_v1
                  </option>
                  <option value="eleven_english_sts_v2">
                    eleven_english_sts_v2
                  </option>
                  <option value="eleven_turbo_v2">eleven_turbo_v2</option>
                  <option value="eleven_multilingual_sts_v2">
                    eleven_multilingual_sts_v2
                  </option>
                </select>
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="voice_model_id_2"
                label="Voice Model Id 2 (for Elevenlabs API Key 2)"
                rules={[
                  { required: false, message: "Please input voice_model_id" },
                ]}
              >
                <select
                  className="form-control"
                  name="voice_model_id_2"
                  id="voice_model_id_2"
                >
                  <option value="eleven_multilingual_v2">
                    eleven_multilingual_v2
                  </option>
                  <option value="eleven_multilingual_v1">
                    eleven_multilingual_v1
                  </option>
                  <option value="eleven_monolingual_v1">
                    eleven_monolingual_v1
                  </option>
                  <option value="eleven_english_sts_v2">
                    eleven_english_sts_v2
                  </option>
                  <option value="eleven_turbo_v2">eleven_turbo_v2</option>
                  <option value="eleven_multilingual_sts_v2">
                    eleven_multilingual_sts_v2
                  </option>
                </select>
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="open_ai_model"
                label="Open AI Model"
                rules={[
                  {
                    required: false,
                    message: "Please input the Open AI Model Name",
                  },
                ]}
              >
                <Input
                  type="text"
                  min={1}
                  placeholder="Please input the Open AI Model Name"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="link_search_model"
                label="Link Search Model"
                rules={[
                  {
                    required: false,
                    message: "Please input the Link Search Model Name",
                  },
                ]}
              >
                <Input
                  type="text"
                  min={1}
                  placeholder="Please input the Link Search Model Name"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="intent_engine_model"
                label="Intent Engine Model"
                rules={[
                  {
                    required: false,
                    message: "Please input the Intent Engine Model Name",
                  },
                ]}
              >
                <Input
                  type="text"
                  min={1}
                  placeholder="Please input the Intent Engine Model Name"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="link_search_model_mistral"
                label="Link Search Model Mistral"
                rules={[
                  {
                    required: false,
                    message: "Please input the Link Search Model Name",
                  },
                ]}
              >
                <Input
                  type="text"
                  min={1}
                  placeholder="Please input the Link Search Model Name"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="intent_engine_model_mistral"
                label="Intent Engine Model Mistral"
                rules={[
                  {
                    required: false,
                    message: "Please input the Intent Engine Model Name",
                  },
                ]}
              >
                <Input
                  type="text"
                  min={1}
                  placeholder="Please input the Intent Engine Model Name"
                />
              </Form.Item>
            </div>

            <div style={{ width: "100%" }}>
              <Form.Item
                {...formItemLayout}
                name="text_msg_price"
                label="Text message price ($/each)"
              >
                <Input
                  type="text"
                  placeholder="Text message price"
                  pattern="[0-9.,]*"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="audio_price"
                label="Audio minute price ($/minute)"
              >
                <Input
                  type="text"
                  placeholder="Audio minute price"
                  pattern="[0-9.,]*"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="image_returned_cost"
                label="Image returned cost ($/each)"
              >
                <Input
                  type="text"
                  min={0}
                  placeholder="Image returned cost"
                  pattern="[0-9.,]*"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="swf_image_price"
                label="SWF image price ($/each)"
              >
                <Input
                  type="text"
                  placeholder="SWF image price"
                  pattern="[0-9.]*"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="nswf_image_price"
                label="NSWF image price ($/each)"
              >
                <Input
                  type="text"
                  placeholder="NSWF image price"
                  pattern="[0-9.]*"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="net_profit_cut"
                label="Net profit cut (%/each)"
                rules={[
                  {
                    required: true,
                    pattern: /^[0-9.]*$/,
                    message: "Please input a number greater than 0",
                  },
                  { min: 0, message: "Please input a number greater than 0" },
                  { max: 100, message: "Please input a number less than 100" },
                ]}
              >
                <Input
                  type="text"
                  min={0}
                  max={100}
                  placeholder="Net profit cut"
                  pattern="[0-9.]*"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="human_generated_percent"
                label="Bonus Content. Human generated earn percent (%/each)"
                rules={[
                  {
                    required: true,
                    pattern: /^[0-9.]*$/,
                    message: "Please input a number greater than 0",
                  },
                  { min: 0, message: "Please input a number greater than 0" },
                  { max: 100, message: "Please input a number less than 100" },
                ]}
              >
                <Input
                  type="text"
                  min={0}
                  max={100}
                  placeholder="Human generated earn percent"
                  pattern="[0-9.]*"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="ai_generated_percent"
                label="Bonus Content. AI generated earn percent (%/each)"
                rules={[
                  {
                    required: true,
                    pattern: /^[0-9.]*$/,
                    message: "Please input a number greater than 0",
                  },
                  { min: 0, message: "Please input a number greater than 0" },
                  { max: 100, message: "Please input a number less than 100" },
                ]}
              >
                <Input
                  type="text"
                  min={0}
                  max={100}
                  placeholder="AI generated earn percent"
                  pattern="[0-9.]*"
                />
              </Form.Item>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <Form.Item
              {...formItemLayout}
              name="description"
              label="Description"
            >
              <TextArea rows={2} placeholder="Please input description" />
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <Modal
        title={"Publish on main page"}
        open={isActiveModalOpen}
        onOk={publishOnMain}
        onCancel={handleClosePublishModal}
      >
        <div>Are you sure?</div>
      </Modal>

      <Modal
        title="Upload Influencer File"
        okText={fileUploadLoading ? "Uploading..." : "Press to save changes"}
        open={isUploadFileModal}
        onOk={handleUploadFileModalOk}
        onCancel={handleCloseUploadFileModal}
        okButtonProps={{ disabled: fileUploadLoading }}
        cancelButtonProps={{ disabled: fileUploadLoading }}
        width={1000}
      >
        <Form form={formFile} name="dynamic_rule" layout="vertical">
          <div style={{ display: "flex", gap: "15px" }}>
            <div style={{ width: "460px" }}>
              <Form.Item
                name="profile"
                label="Profile Picture"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: "Please upload a Profile Picture",
                  },
                ]}
              >
                <Upload
                  name="profile_picture"
                  action={
                    "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                  }
                  accept="image/*"
                  listType="picture"
                  fileList={profilePictureList}
                  multiple={false}
                  beforeUpload={() => false}
                  maxCount={1}
                  onChange={(e) => {
                    setProfilePictureList(e.fileList);
                  }}
                  onRemove={(e) => {
                    setProfilePictureList([]);
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>

              <Form.Item
                name="examples"
                label="Generation Image Examples"
                getValueFromEvent={normFile}
              >
                <Upload
                  name="example_files"
                  action={
                    "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                  }
                  accept="image/*"
                  listType="picture"
                  fileList={examplesPictureList}
                  multiple={true}
                  beforeUpload={() => false}
                  onChange={(e) => {
                    setExamplesPictureList(e.fileList);
                  }}
                  onRemove={(e) => {
                    setExamplesPictureList([]);
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            </div>

            <div style={{ width: "460px" }}>
              <Form.Item
                name="cover"
                label="Cover Image/Video"
                getValueFromEvent={normFile}
              >
                <Upload
                  name="cover_file"
                  action={
                    "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                  }
                  listType={
                    coverPictureList.length > 0 &&
                    coverPictureList[0].url &&
                    isImage(coverPictureList[0].url)
                      ? "picture"
                      : "text"
                  }
                  fileList={coverPictureList}
                  multiple={false}
                  beforeUpload={() => false}
                  maxCount={1}
                  onChange={(e) => {
                    setCoverPictureList(e.fileList);
                  }}
                  onRemove={(e) => {
                    setCoverPictureList([]);
                  }}
                >
                  <div style={{ marginBottom: "5px", color: "red" }}>
                    <label>Width: 1560px, Height: 340px</label>
                  </div>
                  {coverPictureList.length > 0 &&
                    coverPictureList[0].url &&
                    !isImage(coverPictureList[0].url) && (
                      <ReactPlayer
                        width={460}
                        controls={true}
                        url={coverPictureList[0].url}
                      />
                    )}
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="promptFirst"
                label="Image Example Prompt 1"
              >
                <TextArea rows={2} placeholder="Image Example Prompt 1" />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="promptSecond"
                label="Image Example Prompt 2"
              >
                <TextArea rows={2} placeholder="Image Example Prompt 2" />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="promptThird"
                label="Image Example Prompt 3"
              >
                <TextArea rows={2} placeholder="Image Example Prompt 3" />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="promptFourth"
                label="Image Example Prompt 4"
              >
                <TextArea rows={2} placeholder="Image Example Prompt 4" />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        title={"Delete influencer"}
        open={isDeleteModalOpen}
        onOk={deleteRecord}
        onCancel={handleCloseDeleteModal}
      >
        <div>Delete Influencer?</div>
      </Modal>
    </div>
  );
};

export default Influencer;
