import { Button, Modal, Input, Form, Tag, message } from "antd";
import React, { useState, useEffect } from "react";
import customAxios from "../../utils/axios";

import { EditOutlined } from "@ant-design/icons";

import BarLoader from "react-spinners/BarLoader";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ChataboxAutoEngagement = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([{}]);
  const [autoEngagementDelays, setAutoEngagementDelays] = useState([]);
  const [autoEngagementChats, setAutoEngagementChats] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const formItemLayout = null;

  const columns = [
    {
      title: "Auto-engagement status",
      description:
        "Enable or disable auto-engagement functionality in the chat.",
      dataIndex: "auto_engagement_status",
      key: "auto_engagement_status",
      render: (text) => (
        <Tag color={text.toString() === "1" ? "green" : "red"}>
          {text.toString() === "1" ? "Enabled" : "Disabled"}
        </Tag>
      ),
    },
    {
      title: "Auto-engagement number of chats",
      description: "Amount of auto-engagement chats.",
      dataIndex: "auto_engagement_number_of_chats",
      key: "auto_engagement_number_of_chats",
      render: (text) => <Tag>{text}</Tag>,
    },
  ];

  const editChatDelay = (event, order) => {
    let value = event.target.value;

    setAutoEngagementDelays((prev) =>
      prev.map((item, idx) => (idx === order ? +value : item))
    );
  };

  useEffect(() => {
    if (data.auto_engagement_number_of_chats >= 0) {
      let allFields = [];

      for (let i = 0; i < data.auto_engagement_number_of_chats; i++) {
        allFields.push(
          <div>
            Chat #{i + 1} in seconds{" "}
            <input
              onChange={(e) => editChatDelay(e, i)}
              value={autoEngagementDelays[i]}
              type="number"
              placeholder="Seconds"
              onInput={(event) => {
                let value = event.target.value;

                if (value.includes("-")) {
                  value = 0;
                }
                event.target.value = value;
              }}
            />
          </div>
        );
      }

      setAutoEngagementChats(allFields);
    }
  }, [data, autoEngagementDelays]);

  function editDetail(record) {
    form.setFieldsValue({
      auto_engagement_status: record.auto_engagement_status,
      auto_engagement_number_of_chats: record.auto_engagement_number_of_chats,
    });
    showEditMsgModal();
  }

  useEffect(() => {
    getSettingsData();
  }, []);

  const getSettingsData = () => {
    customAxios.get(SERVER_URL + "/system/settings-chatabox").then((res) => {
      setData(res.data[0]);
      setAutoEngagementDelays(JSON.parse(res.data[0].auto_engagement_delays));
      setLoading(false);
    });
  };

  const showEditMsgModal = () => {
    setIsEditModalOpen(true);
  };

  const handleSaveSettings = async () => {
    try {
      await form.validateFields();
      const options = form.getFieldsValue();

      const { data } = await customAxios.put(
        SERVER_URL + "/system/settings-chatabox",
        {
          ...options,
        }
      );

      setData(data[0]);
      setAutoEngagementDelays(data[0].auto_engagement_delays);
      setIsEditModalOpen(false);
      clearFormData();

      messageApi.open({
        type: "success",
        content: "Updated successfully",
      });
    } catch (error) {
      console.log("--ERROR--", error);
    }
  };

  const updateAutoEngagementDelays = async () => {
    try {
      const { data } = await customAxios.put(
        SERVER_URL + "/system/settings-chatabox",
        {
          auto_engagement_delays: autoEngagementDelays,
        }
      );

      setData(data[0]);
      setAutoEngagementDelays(data[0].auto_engagement_delays);
      setIsEditModalOpen(false);
      clearFormData();

      messageApi.open({
        type: "success",
        content: "Updated successfully",
      });
    } catch (error) {
      console.log("--ERROR--", error);
    }
  };

  const handleUserCancel = () => {
    setIsEditModalOpen(false);
    clearFormData();
  };

  function clearFormData() {
    form.resetFields();
  }

  return (
    <div>
      {contextHolder}
      <div></div>
      {loading && (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      )}
      {!loading && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {columns.map((column) => (
            <div
              key={column.key}
              style={{
                margin: "10px",
                padding: "10px",
                border: "1px solid gray",
              }}
            >
              <h3>{column.title}</h3>
              <p>
                <i>{column.description}</i>
              </p>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                <li>{column.render(data[column.dataIndex])}</li>
              </ul>
            </div>
          ))}
          <div style={{ marginLeft: 10, paddingLeft: 10 }}>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              <Button
                style={{ marginTop: 0 }}
                type="primary"
                danger
                onClick={() => {
                  editDetail(data);
                }}
              >
                <EditOutlined />
              </Button>
            </ul>
          </div>

          <div>
            <h4>Auto-engagement Delays</h4>
            {autoEngagementChats.length < 1 && <div>No chats allowed</div>}
            {autoEngagementChats}
            <button onClick={updateAutoEngagementDelays}>
              Update auto-engagement delays
            </button>
          </div>
        </div>
      )}

      <Modal
        title={"Edit auto-engagement settings"}
        open={isEditModalOpen}
        onOk={handleSaveSettings}
        onCancel={handleUserCancel}
      >
        <Form form={form} name="dynamic_rule" layout="vertical">
          <Form.Item
            {...formItemLayout}
            name={"auto_engagement_status"}
            label="Is auto-engagement enabled"
            rules={[{ required: true }]}
          >
            <select
              className="form-control"
              name="auto_engagement_status"
              id="auto_engagement_status"
            >
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="auto_engagement_number_of_chats"
            label="Is auto-engagement number of chats"
            rules={[
              {
                required: true,
                message: "Please add Chat Typing Delay To",
              },
              {
                validator: (_, value) =>
                  value >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be negative"),
              },
            ]}
          >
            <Input type="number" placeholder="" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ChataboxAutoEngagement;
