import { message } from "antd";
import BarLoader from "react-spinners/BarLoader";
import React, { useEffect, useState } from "react";
import customAxios from "../../utils/axios";
import { SERVER_URL } from "../../Constants";

const FreeMessagesSection = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(true);
  const [amtFreeMessages, setAmtFreeMessages] = useState(1);
  const [audioEnables, setAudioEnables] = useState([]);
  const [systemMessages, setSystemMessages] = useState([]);
  const [links, setLinks] = useState([]);
  const [limitReachedMessage, setLimitReachedMessage] = useState("");

  const changeFreeMessages = (e) => {
    setAmtFreeMessages(e.target.value);
  };

  const [freeMessageInputFields, setFreeMessageInputFields] = useState([]);

  const updateAudioEnabled = (enabled, index) => {
    let newAudioEnables = audioEnables;
    newAudioEnables[index] = enabled;
    setAudioEnables(newAudioEnables);
  };

  const updateSystemMessage = (message, index) => {
    let newSystemMessages = systemMessages;
    newSystemMessages[index] = message;
    setSystemMessages(newSystemMessages);
  };

  const updateLink = (link, index) => {
    let newLinks = links;
    newLinks[index] = link;
    setLinks(newLinks);
  };

  useEffect(() => {
    let allFields = [];
    for (let i = 0; i < amtFreeMessages; i++) {
      let audioEnabled = false;
      let systemMessage = "";
      let link = "";

      if (audioEnables[i] !== undefined) {
        audioEnabled = audioEnables[i];
      }
      if (systemMessages[i] !== undefined) {
        systemMessage = systemMessages[i];
      }
      if (links[i] !== undefined) {
        link = links[i];
      }

      allFields.push(
        <div>
          {i + 1}. Message | Audio Allowed{" "}
          <input
            onChange={(e) => updateAudioEnabled(e.target.checked, i)}
            type="checkbox"
            name="messageType"
            defaultChecked={audioEnabled}
          />
          <input
            onChange={(e) => updateSystemMessage(e.target.value, i)}
            style={{ width: "100%" }}
            type="text"
            defaultValue={systemMessage}
            placeholder="System Message (optional)"
          />
          <input
            onChange={(e) => updateLink(e.target.value, i)}
            style={{ width: "100%" }}
            type="text"
            defaultValue={link}
            placeholder="Link (optional)"
          />
        </div>
      );
    }

    allFields.push(
      <div>
        <br />
        Limit reached message
        <input
          onChange={(e) => setLimitReachedMessage(e.target.value)}
          defaultValue={limitReachedMessage}
          style={{ width: "100%" }}
          type="text"
          placeholder="Limit reached Message"
        />
      </div>
    );

    setFreeMessageInputFields(allFields);
  }, [amtFreeMessages]);

  useEffect(() => {
    const fetchData = () => {
      customAxios.get(SERVER_URL + "/free_messages").then(function (res) {
        const { data } = res;
        console.log(data);

        setAmtFreeMessages(data.free_msg_amt);
        setAudioEnables(JSON.parse(data.free_msg_audio_enabled));
        setSystemMessages(JSON.parse(data.free_msg_system_messages));
        setLinks(JSON.parse(data.free_msg_links));
        setLimitReachedMessage(data.free_msg_limit_reached_msg);

        setLoading(false);
      });
    };

    fetchData();
  }, []);

  const saveFreeMessages = async () => {
    customAxios
      .put(SERVER_URL + "/free_messages", {
        free_msg_amt: amtFreeMessages,
        free_msg_audio_enabled: JSON.stringify(audioEnables),
        free_msg_system_messages: JSON.stringify(systemMessages),
        free_msg_links: JSON.stringify(links),
        free_msg_limit_reached_msg: limitReachedMessage,
      })
      .then(function (res) {
        const { data } = res;

        if (data.status === "success") {
          messageApi.open({
            type: "success",
            content: "Free messages saved successfully!",
          });
        } else {
          messageApi.open({
            type: "error",
            content: "Error saving free messages",
          });
        }
      });
  };

  return (
    <div>
      {contextHolder}

      {loading && (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      )}
      {!loading && (
        <>
          <h1>Free Message Settings</h1>
          <input
            min={0}
            type="number"
            placeholder="Amount of free messages"
            defaultValue={amtFreeMessages}
            onChange={(e) => changeFreeMessages(e)}
          />
          {freeMessageInputFields.length < 1 && (
            <div>No free messages allowed</div>
          )}
          {freeMessageInputFields}
          <button onClick={saveFreeMessages}>Save Free Message Settings</button>
        </>
      )}
    </div>
  );
};

export default FreeMessagesSection;
